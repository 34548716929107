// .fa-background{
//     width: 20px;
//     left: 3.33%;
//     right: 95.28%;
//     top: 19.64%;
//     bottom: 78.53%;
//     background: #899CA8;
//     height: 19.67px;
//     color: white;
//     text-align: center;
//     padding: 3px;
// }

.fa-usd {
    width: 20px;
    left: 3.33%;
    right: 95.28%;
    top: 19.64%;
    bottom: 78.53%;
    background: #899CA8;
    height: 19.67px;
    color: white;
    text-align: center;
    padding: 3px;
    font-size: smaller !important;
}
// .fa-usd::selection {
//     width: 20px!important;
//     left: 3.33%!important;
//     right: 95.28%!important;
//     top: 19.64%!important;
//     bottom: 78.53%!important;
//     background: #000000!important;
//     height: 19.67px;
//     color: white!important;
//     text-align: center!important;
//     padding: 3px!important;
//     font-size: smaller !important;
// }