.range_amount {
  font-family: 'Lato';
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 18px !important;
  line-height: 19px !important;
  color: #000000;
}
.para_PS {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  color: #656565;
}

.para_amount {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 39px;
  letter-spacing: 0.055em;
  color: #656565;
}
.para_amount_PKR {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  color: #443087;
  padding-left: 3px;
  padding-right: 3px;
}
.input_amount {
  margin-left: 0.25rem;
  min-width: 1.5rem;
  border-color: transparent;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  max-width: 3.5rem !important;
}
.para_amount > input[type='number'] {
  color: #656565 !important;
}
