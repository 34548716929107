.field-label1 {
  font-family: 'Playfair Display' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #000000 !important;
}

.modalStyle {
  height: 30rem;
  // margin-top: 1rem;
  // margin-bottom: 3rem;
}
