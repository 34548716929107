.buttonClass {
    /* background-color: pink !important; */
    padding: 0.8rem; 
    border: 0px;
    color: white;
    width: 175px;
    height: 55px;
    left: 1204px;
    top: 138px;
    background: #49BDD8;
    border-radius: 14px;
}

.buttonClass:hover {
    background-color: #2eb9d8 !important;
    transition: 2s;
}
