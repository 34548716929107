body {
    margin: 0px;
    // background-color: #f5f5f5 !important;
    padding: 0px;
}

#root {
    height: 100% !important;
}

.login_body {
    background: #f8fafb !important;
    display: flex;
    justify-content: center;
    min-height: -webkit-fill-available;
}

.react-tel-input {
    width: 100%;
}

.Login {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    align-content: center;
}

.login_Card {
    border-radius: 24px;
    // width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 80px;
    gap: 25px;
    min-width: 552px;
}

.Login_heading {
    font-family: 'Playfair Display' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 35px;
    color: #363636;
    margin: 0px;
    margin-bottom: 1.563rem !important;
}

.label_login {
    text-decoration: none !important;
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    cursor: pointer !important;
    color: #363636 !important;
}

.label_login a {
    text-decoration: none !important;
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #363636 !important;
}

.forgot_heading {
    font-family: 'Playfair Display' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 35px;
    color: #363636;
    margin: 0px;
}

.ReEmail_heading {
    font-family: 'Playfair Display' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
    color: #363636;
    margin: 0px;
    margin-bottom: 1.5rem;
}

.img {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    width: 15rem;
    height: 4rem;
}

.label {
    font-family: 'Lato', sans-serif;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    margin-top: 0.938rem !important;
    color: #363636 !important;
}

.Form_Main {
    width: 100%
}

.otpForm {
    padding: 1rem 4.188rem 2rem;
}

.Form_Secondary {
    width: 100% !important;
}


.acc_label {
    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #899CA8 !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

.link {
    color: #49BDD8 !important;
    font-family: 'Lato', sans-serif !important;
    text-decoration: none !important;
}

.MuiFormControl-root-MuiTextField-root {
    margin-top: 0px !important;
    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #363636 !important;
}

.field-label {
    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #899CA8 !important;
}

.MuiFormLabel-asterisk {
    color: red;
}
.MuiOutlinedInput-input{
    padding: 10px 14px !important;
    height: 24px  !important;
}

.MuiOutlinedInput-root {
    border-color: #D0D5DD !important;
    // color: red !important;
    border-radius: 8px !important;
    // font-family: 'Lato', sans-serif !important;
    // height: 3.25rem !important;
    align-self: center;
    &.MuiOutlinedInput-root fieldset {
        border-color: #D0D5DD !important;
    }

    &:hover fieldset {
        border-color: #D0D5DD !important;
    }

    &.Mui-focused fieldset {
        border-color: #d6d6d6 !important;
    }

}

.react-tel-input .flag-dropdown {
    border: 1px solid #D0D5DD !important;

    // border-radius: 20px 0px 0px 20px !important;
    // margin:10px !important;
    &.react-tel-input .flag-dropdown.active {
        border: 1px solid #D0D5DD !important;
    }

    &.react-tel-input .flag-dropdown:hover {
        border: 1px solid #D0D5DD !important;
    }

    &.react-tel-input .flag-dropdown:focus {
        border: 1px solid #D0D5DD !important;
    }
}

.react-tel-input .selected-flag {
    border: none !important;
    // border-radius: 8px 0px 0px 8px !important;
    background-color: rgb(255, 255, 255) !important;

    &.react-tel-input .flag-dropdown.active {
        border: 1px solid #D0D5DD !important;
    }

    &.react-tel-input .selected-flag:hover {
        border: 1px solid #D0D5DD !important;
    }

    &.react-tel-input .selected-flag:focus {
        border: 1px solid #D0D5DD !important;
    }
}

.react-tel-input .form-control {
    border: 1px solid #D0D5DD !important;
    color: #363636 !important;
    // border-radius: 1.25rem !important;
    // font-family: 'Lato', sans-serif !important;
    height: 3.25rem !important;
    width: 100% !important;
    align-self: center !important;
    // margin-top: 10px !important;
    // margin-bottom: 0 !important;
    border-radius: 8px !important;

    &.form-control.active {
        border-color: #D0D5DD !important;
    }

    &.form-control:hover {
        border-color: #D0D5DD !important;
    }

    &.form-control:focus {
        border-color: #D0D5DD !important;
    }
}

.email_Input {
    margin-bottom: 0rem !important;
    margin-top: 0.438rem !important;
}

// .MuiSvgIcon-root {
//     color: #899CA8;
// }

.btn_Signin {
    background-color: #49BDD8 !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: 400 !important;
    font-style: normal;
    font-size: 18px !important;
    line-height: 22px !important;
    text-align: center;
    color: #ffffff !important;
    border-radius: 0.938rem !important;
    margin-bottom: 1.313rem !important;
    margin-top: 1.75rem !important;
    height: 3.4375rem;
    text-transform: none !important;
    list-style: cambodian;
    box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16) !important;
}

// .btn_Signin {

//     background-color: #49BDD8 !important;
//     font-family: 'Lato', sans-serif !important;
//     font-weight: 400 !important;
//     font-style: normal;
//     font-size: 18px !important;
//     line-height: 22px !important;
//     text-align: center;
//     color: #ffffff !important;

//     margin-bottom: 1.313rem !important;
//     margin-top: 1.75rem !important;
//     box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16)  !important;
// }
.btn_SigninDisable {
    background-color: #7c7c7c !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: 400 !important;
    font-style: normal;
    font-size: 18px !important;
    line-height: 22px !important;
    text-align: center;
    height: 3.4375rem;
    text-transform: none !important;
    color: #ffffff !important;
    border-radius: 0.938rem !important;
    margin-bottom: 1.313rem !important;
    margin-top: 1.75rem !important;
    box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16) !important;
}

#otp-field input {
    color: #6e6e6e;
    text-align: center;
    margin-left: 10px !important;
    margin-right: 10px !important;
    background: #FFFFFF !important;
    border: 1px solid #F5F1FE !important;
    border-radius: 20px !important;
    height: 3.125rem !important;
    width: 3.6406rem !important;
}

#otp-field input:focus {
    /* White */

    background: #FFFFFF !important;
    /* Blue */

    border: 1px solid #49BDD8 !important;
}

#withdrawal-otp-field input{
    color: #6e6e6e;
    text-align: center;
    margin-left: 3px !important;
    margin-right: 3px !important;
    background: #FFFFFF !important;
    border: 1px solid #F5F1FE !important;
    border-radius: 5px !important;
    height: 3.7406rem !important;
    width: 3.7406rem !important;
}

@media (max-width:440px){
    #withdrawal-otp-field input{
        width: 20% !important;
        height: 3.7406rem !important;
        margin-left: 5px !important;
    margin-right: 5px !important;
    }
}

#withdrawal-otp-field input:focus {
    /* White */

    background: #FFFFFF !important;
    /* Blue */

    border: 1px solid #49BDD8 !important;
}

.forgot_text {
    margin-bottom: 1.5rem !important;
    color: #899CA8;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
}

.pass {
    border-radius: 1.25rem !important;
}

/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */

@media (max-width:320px) {
    .Form_Main {
        padding: 1rem 1.188rem 2rem;
    }

    .otpForm {
        padding: 1rem 1.188rem 2rem;
    }

    .login_Card {
        width: 100%;
    }

    .Login {
        width: 100% !important;
    }

    .modal-dialog {
        width: 100% !important;
    }
}

/* smartphones, Android phones, landscape iPhone */
@media (max-width:480px) {
    .Form_Main {
        padding: 1rem 1.188rem 2rem;
    }

    .otpForm {
        padding: 1rem 1.188rem 2rem;
    }

    .login_Card {
        width: 100%;
    }

    .Login {
        width: 100% !important;
    }

    .modal-dialog {
        width: 100% !important;
    }
}

/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
@media (max-width:600px) {
    .Form_Main {
        padding: 1rem 1.188rem 2rem;
    }

    .otpForm {
        padding: 1rem 1.188rem 2rem;
    }

    .login_Card {
        width: 100%;
    }

    .Login {
        width: 100% !important;
    }

    .modal-dialog {
        width: 100% !important;
    }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (max-width:901px) {
    .Form_Main {
        padding: 1rem 1.188rem 2rem;
    }

    .otpForm {
        padding: 1rem 1.188rem 2rem;
    }

    .modal-dialog {
        width: 100% !important;
    }
}

.sss {
    width: 100%;
    margin-bottom: 1rem;
}

.sss {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: absolute;
    // left: 50%;
    // top: 50%;
    // width: 200px;
    // margin-top: 10px;
    // transform: translate(-50%, -50%);
}

input[type=range]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    background: rgba(59, 173, 227, 1);
    filter: rgba(59, 173, 227, 1);
    height: 2px;
}

input[type=range]:focus {
    outline: none;

}

input[type=range]::-moz-range-track {
    -moz-appearance: none;
    background: rgba(59, 173, 227, 1);
    filter: rgba(59, 173, 227, 1);
    height: 5px;
    padding: 5rem;

}

.port_wizard {
    padding-left: 14rem;
    padding-bottom: 1rem;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 7px solid #ffffff;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    max-width: 80px;
    position: relative;
    bottom: 11px;
    background: #49BDD8;
    box-shadow: 0px 0px 0px 2px #49BDD8;
    cursor: -webkit-grab;
    -webkit-transition: border 1000ms ease;
    transition: border 1000ms ease;
}

input[type=range]::-moz-range-thumb {
    -moz-appearance: none;
    border: 2px solid;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    max-width: 80px;
    position: relative;
    bottom: 11px;
    background-color: rgb(59, 173, 227);
    cursor: -moz-grab;
    -moz-transition: border 1000ms ease;
    transition: border 1000ms ease;
}

.range.blue::-moz-range-thumb {
    border-color: rgb(59, 173, 227);

}

input[type=range]::-webkit-slider-thumb:active {
    cursor: -webkit-grabbing;
}

input[type=range]::-moz-range-thumb:active {
    cursor: -moz-grabbing;
}

.acc_phone {
    width: 29rem !important;
}