.inputfield {
    /* margin-top: 3%; */
}

.div-height {
    height: 40rem !important;
    min-height: 2rem !important;
    background-color: red;
}

.inputBox {
    background-color: red;
    width: 25rem !important;
}

.inputBox:hover {
    border-color: #00CFE8;
}

.divgap {
    margin-top: 1%;
}

.NewButton {
    background-color: transparent;
    color: black;
    width: 10rem;
    height: 3rem;
}

.ResendOtp {
    border: 0px solid transparent;
    background-color: transparent;
    font-weight: bold;
    cursor: pointer;

}

.NewButton:hover {
    background-color: #4012A0 !important;
    color: white !important;
}

.buttonDisable:disabled {
    background-color: lightgray !important;
    border-color: transparent !important;
}

.selectDropDown {
    margin-left: 3%;
    margin-right: 15%;
}

.TextBoxes {
    border: 1px solid #00CFE8 !important;
    /* border-radius: 1%; */
}

.RowCursor {
    cursor: pointer;
}

.CardDiv {
    border: 1px solid #899CA8;
    padding: 5px;
}

.linkHeading {
    font-family: 'PlayFair';
    font-size: '18px';
    font-weight: 700;
}
.linkColor{
    color: black;
}