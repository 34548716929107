.UpdateButton {
    width: 133px!important;
    height: 48px!important;
    left: 742px!important;
    top: 566px!important;
    color: #ffffff!important;
    border: none!important;
    background: #49BDD8;
    border-radius: 15px!important;
    float: right;
}
