
.side_bar{
    // position: fixed;
    // left: 2.08%;
    // text-align: center;
    // /* right: 83.82%; */
    // top: 3.79%;
    // bottom: 2.89%;
    // /* background: #FFFFFF; */
    // border: 1px solid #F5F1FE;
    // border-radius: 20px;
    // left: 2.08%;
    // right: 83.82%;
    // top: 2.79%;
    // bottom: 2.89%;
    // background: #FFFFFF;
    // border: 1px solid #F5F1FE;
    // border-radius: 20px;
    // position: fixed!important;
    // top: 3.79%;
    /* right: 83.82%; */
    // max-width: 16rem!important;
    // height: 36rem;
    // height: 93%!important;
    // top: 1.5rem!important;
    // left: 1.5rem!important;
    // bottom: 2.47rem;
    // background: #FFFFFF!important;
    // border: 1px solid #F5F1FE!important;
    // border-radius: 20px!important;
    // z-index: 100;

    // position: fixed !important;
    // right: 85%;
    // max-width: 20rem !important;
    // height: calc(100vh - 58px);
    // top: 1.5rem !important;
    // left: 1.5rem !important;
    // background: #FFFFFF!important;
    // border: 1px solid #F5F1FE!important;
    // border-radius: 20px!important;
    // box-shadow: 0 14px 24px rgba(0, 0, 0, 0.20)!important;
    position: relative;
    right: 1rem;
    max-width: 13rem !important;
    height: calc(100vh - 100px);
    top: 2rem !important;
    left: 1rem !important;
    background: #FFFFFF !important;
    border: 1px solid #F5F1FE !important;
    border-radius: 20px !important;
    // box-shadow: 0 14px 24px rgba(0, 0, 0, 0.20) !important;
    z-index: 1;
}
.rotate {
    transform: rotate(90deg)
}

// .css-a8c9o1-MuiList-root {
//     list-style: none;
//     margin: 0;
//     padding: 0;
//     position: relative;
//     padding-top: 8px;
//     padding-bottom: 8px;
//     /* position: fixed; */
//     /* margin-bottom: 1.5rem; */
//     top: 14rem!important;
// }
// .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root:hover {
// color: #443087!important;
// border-radius: 10px!important;
// background-color: #3D2B7D;
//  &.fa:hover{

//  }
// }
.icon {
    color: #ffffff;
    background: #899CA8;
    border-radius: 5px;
}
.iconactive {
    color: #ffffff;
    background: #443087;
    border-radius: 5px;
}
.test{
    background-color: #f5f5f5;
}
.maincol {
    background-color: #f5f5f5;
}
.sideTabContent1 {
    background-color: rgba(68, 48, 135, 0.18)!important;
    border-radius: 10px!important;
    color: #443087!important;
    
  }
  .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    border-radius: 10px!important;
}
  .sideTabConten{
    border-right: 4px solid #443087;
    border-radius: 2px;
    height: 42.86px;
  }
  .liststyle{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }
//   .sideTabContent1 span {
//     background: #443087;
//     mix-blend-mode: normal;

//     border-radius: 10px;
//     color: #443087!important;
//   }
  
.bar_btn {
    justify-content: center !important;
    align-items: center !important;
    padding: 3px !important;
    gap: 5px !important;
    width: 25% !important;
    height: auto !important;
    background: #F6F5F9 !important;
    border-radius: 10px !important;
    color: #3D2B7D !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 15px !important;
    margin-right:1rem!important;
    /* Purple */
    &:focus {
        background: #3D2B7D !important;
        color: #F6F5F9 !important;
        border-radius: 10px !important;
    }

}
.recharts-cartesian-axis-tick-line{
    // display: none;
}
.recharts-layer{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: right;

}
.grey1 {

    margin-bottom: 0rem;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* identical to box height, or 131% */


    /* 3 */

    color: #899CA8;

}
.Pie_heading{

    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 22.3px;
    line-height: 27px;
    /* identical to box height */
    
    
    /* 4 */
    
    color: #363636;

}
.current_heading {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #000000;
}
.Welcome_heading {
    font-family: 'Playfair Display';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 24px;
display: flex;
align-items: center;
color: #656565;
}
.username_heading{
    font-family: 'Playfair Display';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 37px;
color: #000000;
}
.username_para{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #656565;
}
.over_heading {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 700;
    font-size: 22.3px;
    line-height: 27px;
    /* identical to box height */


    /* 4 */

    color: #363636;
}

.grey {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    color: #899CA8;
    margin-bottom: 0.5rem;
}

.color {
    color: #605292 !important;
}

.color1 {
    color: #8F86B0 !important;
}

.color2 {
    color: #C5C2D2 !important;
}

.heading1 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 22.3px;
    line-height: 27px;
    /* identical to box height */


    /* 4 */

    color: #363636;
}

.heading2 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 122% */


    /* 4 */

    color: #363636;
}

.MuiButton-outlined {

    /* Auto layout */

    // display: flex !important;
    // flex-direction: row !important;
    // justify-content: center !important;
    // align-items: center !important;
    // // padding: 11px 16px !important;
    // gap: 10px !important;
    // margin-top: 1rem!important;
    // margin-bottom: 1rem!important;
    // // width: 100% !important;
    // height: auto !important;


    // /* Blue */
    // color: #49BDD8 !important;
    // border: 1px solid #49BDD8 !important;
    // border-radius: 14px !important;
}



// /* smartphones, Android phones, landscape iPhone */
@media (max-width:480px) {
  .test {
        display: none !important;
    }

    #main {
        max-width: 100% !important;
    }

}

@media (max-width:1100px) {

    .test {
        display: none !important;
    }
    .maincol{
        flex: 0 0 99.333333%!important;
        max-width: 100.333333%!important;
    }
}