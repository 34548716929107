.Oye{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
     cursor: pointer;
    &:hover{
        color: #49BDD8 !important;
    }
}
.modal-header .close {
    font-size: 40px !important;
    color: #000000 !important;
}