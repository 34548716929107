// .container {
//     overflow-x: auto;
//     margin-right: auto;
//     margin-left: auto;
//     margin-top: 50px;
//     padding: 10px;
//     margin: 10px;
//   }

// .title {
//   margin-top: 20px;
//   text-align: center;
// }

// tr {
//   height: 40px !important;
// }

// th {
//   max-width: 125px;
//   min-width: 125px;
//   padding: 0 !important;
//   overflow-x: auto;
//   white-space: nowrap;
//   text-align: center !important;
// }

// td {
//   height: 40px !important;
//   padding: 5px !important;
//   text-align: center !important;
// }
.fa-trash {
  color: red!important;
  font-size: 18px;
  /* background: red; */
  width: 2rem;
  height: 2rem;
  display: flex !important;
  text-align: center;
  background-color: rgba(234, 84, 85, 0.12);
  border-radius: 5px;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.table_header {
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  color: #000000 !important;
  
}
.table_text{
  font-family: 'Lato' !important;
font-style: normal !important;
font-weight: 400 !important;
font-size: 14px !important;
line-height: 39px !important;
}