@media (max-width:991px) {
    .modal-dialog {
      max-width: 100% !important;
      margin: 1.75rem auto; } 
    .MuiStepLabel-label.Mui-completed{
display: none;
    }}
//     @media (max-width:661px) {
//       .res {
//         width: 100%;
//         display: flex;
//         flex-flow: row nowrap;
//         place-content: center;
//         height: 12rem;
//         flex-direction: column!important;
//         flex-wrap: wrap!important; } 
//         .res-modal{
//           height: 40rem!important;
//         }
// }