input[type='radio']:after {
    /* width: 17px;
    height: 17px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid grey; */
}

input[type='radio']:checked:after {
    /* width: 17px;
    height: 17px;
    border-radius: 19px;
    top: -2px;
    margin: 10px;
    left: -1px;
    position: relative;
    
    background-color: #49BDD8;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #49BDD8; */

}

.chartOpacity {
    opacity: 0.84;
    /* padding-bottom: 8%; */
}


.img_cont {
    height: 282px;
    background: #F8FAFB;
    border: 1px dashed #EDEDED;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 20px
}

.question_ {
    font-family: 'Inter,sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #656565;
}

.confirmation_no_button {
    background: transparent !important;
    border-color: #49bdd8 !important;
    color: #49bdd8 !important;
}

.confirmation_yes_button {
    background: #49bdd8 !important;
    border-color: #49bdd8 !important;
}