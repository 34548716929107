.elipseStylePara {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines before ellipses */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    
}

.elipseStyleText{
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Number of lines before ellipses */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}