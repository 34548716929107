.heading {
  font-family: 'PlayFair';
  font-size: 16px;
  font-weight: 600;
}

.payment-detail-div {
  background: #fafafa;
  mix-blend-mode: normal;
  padding: 1rem;
  border-radius: 10px;
}
.Invest-text {
  background: #fafafa;
  mix-blend-mode: normal;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 10px;
  margin-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.detailDiv {
  font-size: 20px;
}
.detail-font-weight {
  font-weight: 600;
  font-family: 'playfair Display';
}
