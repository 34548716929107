.wizard {
    background-color: pink;
    display: flex !important;
}

.NextButton {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    width: 133px !important;
    height: 48px !important;
    left: 742px !important;
    top: 566px !important;
    color: #ffffff !important;
    background: #403081;
    border: 1px solid #403081;
    border-radius: 6px;
}

.NextButton:hover {
    transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    transform: translateY(-3px);
    background-color: rgb(51, 38, 103);
    border: 1px solid rgb(51, 38, 103);
    box-shadow: rgba(45, 34, 90, 0.3) 0px 10px 20px 0px;
}


.BackButton {
    font-family: 'Inter,sans-serif';
    font-style: "normal";
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    background: #EDEDED;
    color: #202020;
    border: 1px solid #EDEDED;
    border-radius: 6px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.BackButton:hover {
    background: #ededede6;
    transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    transform: translateY(-1px);
    box-shadow: rgba(45, 34, 90, 0.3) 0px 10px 20px 0px;
}

.NextButton:disabled {
    background-color: grey;
    border: 1px solid grey;
    cursor: no-drop;
}

.NextButton:disabled:hover {
    background: grey;
    transform: unset;
    box-shadow: unset
}

.BackButton:disabled {
    color: #adb1b2;
    background-color: rgb(242 242 242);
    cursor: no-drop;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: #49BDD8 !important;
}

.MuiStepLabel-label {
    /* font-family: 'Lato'!important;
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 16px!important;
    line-height: 26px!important;
    color: #49BDD8!important; */
}

.MuiStepLabel-label.Mui-active {
    color: #49BDD8 !important;
    font-weight: 500 !important;
}

.MuiSvgIcon-root-MuiStepIcon-root {
    fill: white !important;
    color: rgb(255 255 255) !important;
    border: 1px solid powderblue !important;
    border-radius: 45rem !important;
}

.MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    fill: white !important;
    color: rgb(255 255 255) !important;
    border: 1px solid #49BDD8 !important;
    border-radius: 45rem !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: #49BDD8 !important;
    border: transparent !important;
    border-radius: 0px !important;
}

.MuiStepIcon-text {
    /* fill: #49BDD8 !important;
    background-color: white !important;
    font-size: 0.75rem !important;
    font-family: "latu", sans-serif !important; */
}

.css-1hv8oq8-MuiStepLabel-label.Mui-completed {
    color: #49BDD8 !important;

}

#wizardForm {
    height: '28rem' !important;
}

/* .BackButton{
    border: 0px solid transparent;
    background-color: transparent;
    margin-right: 2.5rem;
} */