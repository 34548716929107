.EllipsesStyles{
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines before ellipses */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width:700px) {
    .responsive{
        width: 100%;
    }
}

@media (max-width:600px) {
    .responsive{
        width: 100%;
    }
}
