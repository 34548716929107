.testimonial-one img {
  display: unset !important;
  width: 90%;
}

.coinDetails-datepiker .picker__container {
  position: relative !important;
}

.react-tel-input {
  width: 29rem;
}

.ph_lay {
  width: 29rem !important;
}

.app-select {
  width: 29rem !important;
}

.wallet-card {
  margin: 0 auto;
  width: 95% !important;
}

.dataTables_wrapper .dataTables_paginate span .paginate_button {
  display: inline-block;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type='radio']:checked+label::after {
  background-color: rgba(1, 1, 1, 0) !important;
}

.element-right-round button#dropdown-button-drop-dwon {
  border-radius: 0 0.75rem 0.75rem 0;
}

._i-hover:hover {
  left: 0 !important;
}

@media screen and (min-width: 695px) {
  .d-unset {
    display: unset !important;
  }
}

.d-unset {
  display: block;
}

button#check-minutes {
  border-radius: 0 0.75rem 0.75rem 0;
}

.fc-toolbar-chunk {
  display: flex;
}

@media screen and (max-width: 335px) {
  .fc-toolbar-chunk {
    display: block;
    text-align: center;
    line-height: 2.8;
  }

  .fc-direction-ltr .fc-toolbar>*> :not(:first-child) {
    margin: 0 !important;
  }
}

/* Editor */
.tox .tox-notification--warn,
.tox .tox-notification--warning {
  display: none !important;
}

/* Map */
svg.svg-map {
  stroke: white !important;
}

.svg-map__location {
  fill: #eff2f4 !important;
}

.svg-map__location:focus,
.svg-map__location:hover {
  fill: black !important;
}

.p-meddle {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

/* color picker */

.chrome-picker {
  position: absolute;
  top: -310px;
}

.color-gradian-tixia .popover {
  top: -410px !important;
}

/* date piker */
@media screen and (max-width: 590px) {
  .rangeDatePicker .calendar__item {
    display: block !important;
    width: 100%;
  }

  .rangeDatePicker .calendar__container {
    width: 100% !important;
  }
}

form#step-form-horizontal div:first-child>div:last-child {
  text-align: right;
}

label.mailclinet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #eef5f9;
  text-align: center;
  margin: auto;
}

.wizard-four-color {
  color: #222fb9;
  text-decoration: underline;
}

.redio-false {
  display: none;
}

span.mail-icon {
  font-size: 3rem;
  display: inline-block;
  line-height: 1;
  margin-top: -1rem;
}

span.mail-text {
  font-size: 1rem;
  text-align: center;
  margin-top: 0.5rem;
}

/* Wizard end */

/* cursor-pointer */
.cursor-pointer {
  cursor: pointer;
}

/* form */
#multiselect button.btn.dropdown-toggle.btn-light {
  background: no-repeat;
  border: 1px solid #d7dae3;
  color: #3e4954;
  font-weight: 100;
}

/* slider */

.card-text {
  margin-bottom: 0;
}

#whiteSpace {
  white-space: normal;
}

#tp-btn::after {
  content: '';
}

.swal-footer {
  text-align: center !important;
  margin: 0 !important;
}

.ui .progress .progress-bar {
  animation-name: animateBar;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.6s;
}

@keyframes animateBar {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

ol.go2441762052 {
  text-align: center;
}

#order2 {
  order: 2;
}

/* Editor */

.summernote> :first-child {
  min-height: 250px;
  border: 1px solid #a9a9a9;
  border-color: #f0f1f5;
}

.rdw-image-modal {
  right: 0 !important;
  left: unset !important;
}

.rdw-editor-toolbar {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

/* Button */
button:focus,
a:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Chart */

.go2478912419 {
  text-align: center;
  display: block;
  margin-bottom: 2rem;
}

.picker-input__text,
.range-picker-input {
  width: 100% !important;
}

.timepicker-container,
.MuiFormControl-root.MuiTextField-root {
  flex: 1 1 auto;
}

.timepicker-container input {
  background: #fff;
  border: 1px solid #f0f1f5;
  color: #b1b1b1;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  height: 41px;
  width: 100%;
  box-sizing: border-box;
}

.MuiFormControl-root.MuiTextField-root {
  display: block;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  width: 100%;
  height: 100%;
}

.dd .nestable-item-name {
  color: #fff;
  background: #363062;
  display: block;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px;
  text-decoration: none;
  font-weight: 700;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 13px;
  margin-bottom: -4px;
}

/* Picker */

.i-false:after {
  content: none !important;
}

rect#chart_ChartBorder {
  fill: transparent;
}

/* pagination */
.pagination {
  margin-bottom: 0 !important;
}

@import url('https://fonts.googleapis.com/css?family=Lato');

.rc-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 80;
}

.rc-backdrop.invert {
  background: rgba(1, 1, 1, 0.7);
}

.range-picker-input {
  display: inline-flex;
  border: 1px solid #e4e4e4;
  width: 300px;
}

.range-picker-input * {
  box-sizing: border-box;
}

.range-picker-input__icon {
  display: inline-flex;
  align-items: center;
}

.range-picker-input__start,
.range-picker-input__end {
  display: inline-flex;
  flex: 1;
}

.range-picker-input__start .picker-input.range input,
.range-picker-input__end .picker-input.range input {
  width: 100%;
  border: none;
}

.picker-input {
  display: inline-block;
  position: relative;
}

.picker-input__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  display: inline-flex;
  align-items: center;
}


.picker-input__text {
  padding: 10px;
  border: 1px solid #e4e4e4;
  outline: none;
  font-size: 16.8px;
}

.picker-input__text:disabled {
  background: #e4e4e4;
}

.picker-input__clear {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
}

.a {
  color: red !important;
}

.picker {
  display: inline-block;
}

.picker__container {
  position: absolute;
  z-index: 100;
}

.picker__container.portal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.picker__container__include-time {
  border: 1px solid #e4e4e4;
}

.picker__container__include-time .calendar__item,
.picker__container__include-time .time__container {
  border: none;
}

.picker__container__tab {
  margin: 10px 0;
}

.picker__container__tab button {
  padding: 5px 10px;
  outline: none;
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  border-bottom: 2px solid #e4e4e4;
}

.picker__container__tab button.active {
  color: #49599a;
  border-bottom: 2px solid #49599a;
}

.picker__container__tab button:first-child {
  border-right: none;
}

.picker__container__tab button svg {
  margin-right: 5px;
}

.time__container {
  display: inline-flex;
  align-items: center;
  border: 1px solid #e4e4e4;
  padding: 15px;
  background: white;
  font-family: 'Lato';
  font-size: 12px;
}

.time__container__div {
  margin: 0 10px;
}

.time__container__type {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.time-input {
  display: inline-block;
  width: 40px;
  overflow: hidden;
}

.time-input__up,
.time-input__down {
  border: 1px solid #e4e4e4;
}

.time-input__up button,
.time-input__down button {
  outline: none;
  width: 100%;
  cursor: pointer;
  border: none;
}

.time-input__text {
  width: 100%;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  box-sizing: border-box;
}

.time-input__text input {
  width: 100%;
  box-sizing: border-box;
  border: none;
  font-size: 15px;
  padding: 5px;
  text-align: center;
  outline: none;
}

/* calender */

.react-calendar {
  border: none !important;
  font-family: 'poppins', sans-serif;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: none !important;
}

button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  color: #dee2e6 !important;
  font-family: 'poppins', sans-serif;
}

span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  font-family: 'poppins', sans-serif;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  display: none;
}

button.react-calendar__tile.react-calendar__month-view__days__day {
  border-radius: 10px;
  color: black;
  color: #000;
  font-weight: 500;
  height: 46px;
  line-height: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'poppins', sans-serif;
}

button.react-calendar__navigation__label {
  border-radius: 1.75rem !important;
  font-size: 20px;
}

button.react-calendar__navigation__label:hover {
  color: white !important;
  background: #eeeeee !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__tile--hasActive,
.react-calendar__navigation button[disabled],
.react-calendar__navigation button[disabled]>span {
  background-color: #eeeeee !important;
}

button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day,
button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  position: relative;
  background: #eeeeee !important;
  box-shadow: none;
  border-radius: 10px;
  overflow: unset !important;
  padding: 16px 5px 15px 5px;
  line-height: 1;
}

.react-calendar {
  border: none;
}

.calendar {
  display: inline-block;
  background: white;
  font-size: 12px;
}

.calendar *,
.calendar *:before,
.calendar *:after {
  box-sizing: border-box;
}

.calendar__container {
  width: 270px;
  font-family: 'Roboto', sans-serif;
  display: none;
}

.calendar__list {
  display: table;
}

.calendar__item {
  display: table-cell;
  border: 1px solid #ececec;
}

.calendar__item:not(:first-child) {
  border-left: none !important;
}

.calendar--show {
  display: inline-block;
}

.calendar__head {
  position: relative;
  background: #7986cb;
  padding: 10px 6px;
}

.calendar__head--title {
  font-size: 1.3em;
  color: white;
  text-align: center;
  margin: 4px;
}

.calendar__head--button {
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  font-size: 20px;
}

.calendar__head--button svg {
  fill: white;
}

.calendar__head--prev,
.calendar__head--next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.calendar__head--prev {
  left: 0;
}

.calendar__head--next {
  right: 0;
}

.calendar__panel--show {
  display: block !important;
}

.calendar__panel--today {
  background: #aab6fe;
  padding: 5px;
  display: none;
}

.calendar__panel--today h2 {
  margin: 0;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
}

.calendar__body--table {
  width: 100%;
  table-layout: fixed;
  text-align: center;
  border-spacing: none;
  border-collapse: collapse;
}

.calendar__body--table th {
  height: 30px;
  vertical-align: middle;
  color: #212121;
}

.calendar__day {
  vertical-align: top;
  padding-top: 5px;
  height: 40px;
  cursor: pointer;
}

.calendar__day--0 {
  color: red;
}

.calendar__day--6 {
  color: blue;
}

.calendar__day--today {
  background: #fff9c4;
}

.calendar__day--disabled {
  color: #ddd;
  cursor: initial;
}

.calendar__day--start,
.calendar__day--end,
.calendar__day--selected {
  background: #7986cb;
  color: #fff;
}

.calendar__day--start:hover,
.calendar__day--end:hover,
.calendar__day--selected:hover {
  background: #7986cb;
}

.calendar__day--range {
  background: #dce1ff;
}

.calendar__day--text {
  display: block;
  font-size: 10px;
}

.calendar__year,
.calendar__month {
  height: 55px;
  vertical-align: middle;
}

.calendar__year:hover,
.calendar__month:hover {
  background: #aab6fe;
  cursor: pointer;
}

/* Selector Design */
.css-1okebmr-indicatorSeparator {
  display: none;
}

/* Scroller */
/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

.btn:disabled {
  background-color: #a9a9a9 !important;
  border-color: #a9a9a9 !important;
  cursor: not-allowed;
}

/* #otp-field>div {
  justify-content: center;
  margin-bottom: 16px;
}

#otp-field .separator {
  font-size: 25px;
}

#otp-field input {
  color: #6e6e6e;
  width: 40px !important;
  text-align: center;
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid #d7dae3;
  border-radius: 10px;
}

#otp-field input:focus {
  border-color: #6a60b1;
  outline: 0;
} */

.top-nav-link {
  margin-right: 20px;
}

.header img.logo {
  width: 200px;
  margin-right: 10px;
}

.ptr {
  cursor: pointer !important;
}

.dropmenu .fa.fa-list.fa-lg {
  color: bisque;
}

.dropmenu .fa.fa-credit-card.fa-lg {
  color: #00cfe8;
}

.dropmenu .fa.fa-tachometer.fa-lg {
  color: rgb(218, 198, 18);
}

.active-link {
  color: #00cfe8 !important;
  border-bottom: 3px solid #00cfe8;
}

.rit {
  float: right;
}

.top-grids {
  display: flex;
  justify-content: space-between;
}

.pie-chart .recharts-surface {
  border-radius: 50%;
}

/* #react-select>div>div {
  border-radius: 0.75rem;
} */

.round {
  border-radius: 50%;
}

.icon-cont {
  cursor: pointer;
  top: 30px;
  display: inline-block;
  height: 55px;
  width: 55px;
  position: relative;
  background-color: #efefef;
  border-radius: 50%;
}

.icon-cont span.fa {
  position: absolute;
  top: 38%;
  left: 35%;
}

/* .small-info {
  font-size: 0.8rem;
} */

.small-info {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  color: #656565;
}


.text-cadet {
  color: #00cfe8;
}

.recharts-surface {
  overflow: visible;
}

.align-center {
  align-items: center;
}

/* 
.goal-list-options {
  padding: 10px 20px;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #000;
} */

/* .goal-list-column {
  border-width: 2px solid;
  border-color: #2684FF;
  font-size: 18px;
  margin-top: 10px;
} */

/* .goal-list-column:hover {
  border-width: 2px solid;
  border-color: green;
} */

/* .goal-list-options:last-child {
  margin-bottom: 0;
} */

.donut-chart-text {
  color: #000;
  font-size: 20px;
  position: absolute;
  left: 50%;
  top: 40%;
}

.goal-icon {
  font-size: 55px;
}

.align-end {
  align-items: flex-end;
}

.risk-meter {
  background-image: linear-gradient(to right,
      green,
      #e9802b,
      red);
  border-radius: 3px;
  height: 50px;
  width: 100%;
  position: relative;
}

.labels {
  position: relative;
  font-size: 10px;
  display: flex;
  color: #000;
  justify-content: space-between;
  top: 50px;
}

.risk-meter>.fa {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  font-size: 28px;
}

.risk-meter>.labels span {
  flex-basis: 22%;
  text-align: center;
}

.risk-meter>.labels span:last-child {
  flex-basis: 17%;
  text-align: right;
}

.risk-meter>.labels span:first-child {
  flex-basis: 17%;
  text-align: left;
}

.justify-content-space-between {
  justify-content: space-between;
}

.css-1fhf3k1-control {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  min-height: 48px !important;
  border-radius: 8px !important;
  padding-left: 4px;
  display: flex !important;
  flex-direction: row !important;
  align-content: center !important;
  border: 1px solid #D0D5DD !important;
  background: #F2F4F7 !important;
}

.css-yk16xz-control {
  display: flex !important;
  flex-direction: row !important;
  align-content: center !important;
  background: #FFFFFF !important;
  border: 1px solid #D0D5DD !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  min-height: 48px !important;
  border-radius: 8px !important;
  padding-left: 4px !important;
}

.css-1pahdxg-control {
  display: flex !important;
  flex-direction: row !important;
  align-content: center !important;
  background: #FFFFFF !important;
  border: 1px solid #432F87 !important;
  box-shadow: none !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  min-height: 48px !important;
  border-radius: 8px !important;
  padding-left: 4px !important;
}

.css-4ljt47-MenuList {
  max-height: 129px !important;
  overflow: hidden;
}

.ContaryCol {
  padding: 0px !important;
  margin: 0px !important;
}

.text-phone-number {
  /* box-shadow: 0 0 10px rgb(0 0 0 / 10%); */
  border-radius: 40%;
  border: 1px solid #F5F1FE !important;
}

/* #react-select>div>div,
#react-select>div>*:focus {
  height: 48px!important;
} */

/* #react-select-field>div>div,
#react-select-field>div>*:focus {
  border-radius: 0.75rem;
}  */

.home-main {
  background: url('../images/landing1.png');
  height: 100vh;
  color: #fff;
  padding: 100px 50px;
  min-height: 450px;
}

.home-main .heading,
.products .heading,
.goals .heading {
  font-size: 72px;
  color: #fff;
  line-height: 1;
}

.home-main .info {
  margin-top: 20px;
  font-size: 24px;
  line-height: 1.5;
}

.home-main button {
  background: #000;
  border: none;
}

.goals {
  padding: 50px;
  border-bottom: 12px double #4012a0;
}

.products {
  padding: 50px;
}

.goals .icons {
  display: flex;
  justify-content: space-around;
  font-size: 100px;
  color: #fff;
  align-items: flex-end;
}

.goals .icons:last-child {
  margin-top: -35px;
}

.goals .icons .fa {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 125px;
  flex-basis: 33%;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(0 0 0 / 6%);
}

.anim-head {
  -webkit-animation: 3s linear 0s normal none infinite running bi-gradient;
  animation: 3s linear 0s normal none infinite running bi-gradient;
  background: #0000 linear-gradient(to right,
      #6cc9ff,
      #ff6779,
      #fcc645,
      #ff6779,
      #6cc9ff,
      #ff6779) repeat scroll 0 0/500% auto;
  -webkit-text-fill-color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

@-webkit-keyframes bi-gradient {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes bi-gradient {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 0;
  }
}

.life-time-cnic {
  bottom: 40px;
  left: 20px;
}

.selected-pie {
  left: 10%;
  top: 25%;
  position: absolute;
  z-index: 2;
}

.selected-pie li {
  list-style-type: disc;
}

.secondary-bg {
  background: #0076b8;
}

.secondary-text {
  color: #0076b8;
}

.custom-social-icon {
  background: #fff;
  color: #000;
  padding: 3px;
  border-radius: 2px;
  text-align: center;
  width: 20px;
}

.bottom-border {
  border-bottom: 1px solid #dddfde;
}

.line {
  height: 290%;
  width: 1px;
  background: #dddfde;
  top: 0;
  right: 0;
}

.br {
  border-radius: 4px;
}

.justify-content-space-around {
  justify-content: space-around !important;
}

.alert-msg {
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  font-size: 20px;
}

.singleLine {
  display: flex;
  flex-wrap: nowrap
}

.singleLine>div {
  margin-right: 20px;
}

input[type='text'],
input[type='number'],
input[type='select'],
select {
  color: #1D2939 !important;
  font-family: "Poppins";
  /* border-radius: 2rem; */
}

::placeholder,
#react-select .css-1wa3eu0-placeholder,
#react-select-field .css-1wa3eu0-placeholder {
  color: rgba(1, 1, 1, 0.4) !important;
}

#react-select-field>div>div,
#react-select-field>div>*:focus {
  border-radius: 0.75rem;
}

.h-38 {
  height: 38px !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.px20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.resendText {
  text-align: right;
  cursor: pointer;
  font-size: 14px;
  color: #7e7e7e !important;
  font-weight: 700;
}

.fs-15 {
  font-size: 15px;
}

.py20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.customMargin {
  margin-left: 13px;
}

/* custom css for pagination */
.pagination {
  justify-content: center;
  margin-top: 40px;
}

.style-01 {
  box-shadow: rgb(0 0 0 / 15%) -15px 15px 50px 0px;
  margin-bottom: 3rem;
  margin-left: 13.063rem;
  padding: 1rem;
  /* box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -8px; */
}


#otp-field input::-webkit-outer-spin-button,
#otp-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#otp-field input[type=number] {
  -moz-appearance: textfield;
}

.otpInput::-webkit-outer-spin-button,
.otpInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otpInput[type=number] {
  -moz-appearance: textfield;
}

/* input[type="number"] {
  caret-color: #4012a0;
  -webkit-caret-color: #4012a0;
} */


@media screen and (max-width: 320px) {
  .MuiDialog-scrollPaper .MuiDialog-paper.MuiDialog-paperScrollPaper {
    min-width: auto !important;
    margin: 32px 20px !important;
  }
}