.tableProduct_header {
  font-family: 'Lato'!important;
font-style: normal!important;
font-weight: 400!important;
font-size: 14px!important;
line-height: 24px!important;
letter-spacing: -0.006em!important;
color: #6E7C87!important;

  
}
.tableProduct_text{
  font-family: 'Lato'!important;
  font-style: normal!important;
  font-weight: 400!important;
  font-size: 14px!important;
  line-height: 24px!important;
  letter-spacing: -0.006em!important;
  color: #252C32!important;
}
.tableProduct_header_heading{
  font-family: 'Lato';
font-style: normal;
font-weight: 500;
font-size: 22.3px;
line-height: 27px;
color: #363636;
}

.tableProduct_over_text{
  font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 24px;
/* or 133% */
text-align: left;
color: #656565;
}

.Pruduct_heading{
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 72px;
  /* identical to box height, or 360% */
  
  text-transform: capitalize;
  
  color: #000000;
}
