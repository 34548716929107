.success {
    color: green !important;
    /* color: white !important; */
}

.failure {
    color: red !important;
    /* color: white !important; */
}

.nullValues {
    color: black;
    font-size: 25px;
    font-family: 'Lato';
    font-weight: bold;
}
.adminDashboard{
    padding-top: 8rem;
}