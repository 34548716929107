.wizard {
    background-color: pink;
    display: flex !important;
}

.BackButton {
    width: 133px !important;
    height: 48px !important;
    left: 742px !important;
    top: 566px !important;
    color: #202020;
    background: #EDEDED;
    border: 1px solid #EDEDED;
    border-radius: 6px;
    font-weight: 400;
    font-size: 18px;
    font-family: 'Inter',sans-serif !important;
    font-style: normal;
}

.NextButton {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    width: 133px !important;
    height: 48px !important;
    left: 742px !important;
    top: 566px !important;
    color: #ffffff !important;
    background: #403081;
    border: 1px solid #403081;
    border-radius: 6px;
}

.NextButton:disabled {
    background-color: grey;
    border: 1px solid grey;
    cursor: no-drop;
}
.BackButton:disabled {
    color: #adb1b2;
    background-color: rgb(242 242 242);
    cursor: no-drop;
}
.MuiStepIcon-root.Mui-active {
    color: #49BDD8 !important;
}

.FontClass {
    font-family: 'Lato';
}

.MuiStepLabel-label.Mui-active {
    color: #49BDD8 !important;
    font-weight: 500 !important;
}


.MuiStepIcon-root.Mui-completed {
    color: #49BDD8 !important;
    border: transparent !important;
    border-radius: 0px !important;
}

.MuiStepLabel-label.Mui-completed {
    color: #49BDD8 !important;

}

.MuiStepIcon-root.Mui-active {
    color: #ffffff !important;
    border: 1px solid #49BDD8 !important;
    border-radius: 35px !important;
}

.MuiStepIcon-root.Mui-active>.MuiStepIcon-text {
    fill: #49BDD8 !important;
    font-size: 0.75rem !important;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
}

.MuiStepIcon-root {
    color: #ffffff !important;
    border: 1px solid rgba(0, 0, 0, 0.38) !important;
    border-radius: 35px !important;

}

.MuiStepConnector-line {
    border-color: #49BDD8 !important;
    /* width: 1rem; */
}

#wizardForm {
    height: '28rem' !important;
}

/* .BackButton {
    border: 0px solid transparent;
    background-color: transparent;
    margin-right: 2.5rem;
} */

.MuiStepConnector-line {
    display: block;
    border-color: #a9e0ed;
    border-top-style: solid;
    border-top-width: 1px;

}