.perfbutton {
  border-radius: 10px;
  padding: 13px;
  height: 3rem;
  background: #3D2B7D;
  color: white !important;
  border: 0px solid transparent;


}

.perfbuttonNotActive {
  border-radius: 10px;
  padding: 10px;
  height: 3rem;
  background: #F6F5F9;
  color: #3D2B7D !important;
  border: 0px solid transparent;

}