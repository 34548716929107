/* 11 jan */
.mahaanaLogo {
    height: auto;
    width: 200px;
}

#basic-navbar-nav {
    justify-content: flex-end;
}

.navbar-nav {
    padding: 20px 0;
}

.mahaanaLogo {
    position: relative;
}

.AppHeader,
.AppFooter {
    width: 100%;
    box-shadow: 0 4px 12px 0 rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 5%);
    background-color: "#F8FAFB"
}

.AppHeader .navbar {
    background-color: transparent;
    padding: 15px 3%;
    position: relative;
    z-index: 1;
    box-shadow: none;
}

.AppHeader .navbar-nav {
    padding: 0 0 0 40px;
}

.AppHeader .navbar-nav .nav-link {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    color: var(--black2);
    padding: .5rem 20px;
}

.AppHeader .navbar-nav .nav-link.active {
    font-weight: 700;
    color: var(--primary);
}

.AppHeader .navbar-nav .nav-link:hover {
    cursor: pointer;
    color: var(--secondary);
}

.is-sticky {
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: #ffffff !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 0px;
}

.headerDropdown #collasible-nav-dropdown.nav-link {
    height: 38px;
    width: 38px;
    background: #43BED8;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-left: 20px;
}

.headerDropdown .dropdown-toggle::after {
    display: none;
}

.headerDropdownItem .dropdown-menu {
    margin: 0px;
    width: 200px;
    top: 3rem;
    border: 1px solid rgb(233, 221, 221);
}

.headerDropdown .dropdown-menu {
    margin: 0px;
    width: 200px;
    top: 3rem;
    border: 1px solid rgb(233, 221, 221);
}

.headerDropdownItem .dropdown-toggle {
    font-family: 'Poppins' !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    padding: 0rem !important;
    color: var(--black2) !important;
    width: 100% !important;
}

.headerDropdownItemdrawer {
    padding: 0.8rem 25px !important;
}

.AppHeader .navbar-nav .headerDropdown .nav-link.dropdown-toggle {
    color: var(--white);
    font-size: 20px;
}

.dropdown-toggle::after {
    display: none;
}

.AppHeader .MuiContainer-root {
    margin-top: 0;
    margin-bottom: 0;
}

.AppFooter .MuiBottomNavigationAction-label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    color: #ADADAD;
    flex: none;
    order: 2;
    flex-grow: 0;
}

#basic-navbar-nav {
    display: none;
}

.AppFooterWrapper {
    min-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.navbar-light .navbar-toggler {
    border: none;
}

.navbar-toggler {
    padding: 0,
}

.navbar button.MuiButtonBase-root {
    padding: 0;
    justify-content: flex-end;
    min-width: auto;
}

.Drawer {
    padding: 40px 20px;
    height: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.Drawer .MuiListItem-root a {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    color: var(--black2);
    padding: 0.8rem 25px;
    width: 100%;
}

.Drawer .mahaanaLogo {
    /* margin-bottom: 20px; */
}

.Drawer #collasible-nav-dropdown {
    margin-top: 20px;
    height: 50px;
    width: 50px;
}

.Drawer .dropdown-toggle {
    font-size: 22px;
    color: #fff;
}

.navbar-light .navbar-nav .nav-link {
    margin-left: 20px;
}

#main-wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* ----------- invest-calculator -------------- */


.invest-calculator .recharts-legend-item-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #6B6F80 !important;
    margin-right: 10px;
}

.invest-calculator .recharts-legend-wrapper {
    bottom: 15px !important;
}

.invest-calculator .recharts-rectangle.recharts-tooltip-cursor {
    fill: rgba(244, 244, 244, 1);
}

.invest-calculator tspan {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #838799;
}

.invest-calculator svg {
    cursor: pointer;
}

/* ----------- NIFT -------------- */
.payNowInputWrapper fieldset {
    border: 1px solid #EDEDED !important;
    border-radius: 6px
}

.payNowInputWrapper .Mui-focused fieldset {
    border: 2px solid #43BED8 !important;
}

.payNowInputAdornmentStyle p,
.payNowInput {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #ADADAD;
    text-align: right;
}

.payNowInput {
    font-weight: 400;
    text-align: left;
}

.payNowInputWrapper input::placeholder,
.payNowInput::placeholder {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #ADADAD;
}

.payNowInputWrapper .payNowInputAdornmentStyle {
    position: absolute;
    right: 14px;
    top: 22px;
}

.payNowInputWrapper .text-danger {
    font-size: 12px;
}

.payNowInputWrapper input[readonly],
.pgwhpcacctfield input[readonly] {
    color: #adb1b2 !important;
    background-color: rgb(242 242 242);
    font-weight: 500 !important;
}

.payNowInputWrapper input[readonly].text-box-custom:focus {
    border: 1px solid #EDEDED;
}

.projectionChart .recharts-legend-item-text {
    text-transform: capitalize;
    font-size: 14px;
    color: #6B6F80 !important;
}

.recharts-text.recharts-label tspan {
    font-size: 8px;
    font-weight: 400;
}

.cashfund-calculator tspan {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #A3A3A3;
}

.cashfund-calculator .yAxis tspan {
    font-size: 10px;
    line-height: 15px;
    text-align: right;
}

.cashfund-calculator .recharts-text.recharts-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 4px;

    position: absolute;
    width: 108px;
    height: 36px;

    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    box-shadow: 0px 10px 50px -12px rgba(0, 0, 0, 0.1);
    border-radius: 8.95143px;

}

.cashfund-calculator .recharts-text.recharts-label tspan,
.cashfund-calculator .recharts-label-list text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 116.5%;
    color: #1F2937;
}

.cashfund-calculator path.recharts-rectangle.recharts-tooltip-cursor {
    fill: '#000 !important'
}

.sameAsAddressCheckBox .form-check-input {
    margin-top: 0;
}

.sameAsAddressCheckBox input[type='checkbox'],
.sameAsAddressCheckBox input[type='checkbox']:checked {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;
}

.sameAsAddressCheckBox input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;
    position: relative;
}

.sameAsAddressCheckBox input[type='checkbox']:after,
.sameAsAddressCheckBox input[type='checkbox']:checked:after {
    width: 20px;
    height: 20px;
    /* color: #4012A0;
    background-color: #F9F5FF;
    border: 1px solid #403081; */
    border-radius: 4px;
    border: 1px solid #D0D5DD;
    background: #FFF;
}

.sameAsAddressCheckBox input[type='checkbox']:checked:after {
    /* content: url('../../../Images/svg/secCheckBox.svg'); */
    border-radius: 4px;
    border: 1px solid #403081;
    background: #F9F5FF;
    content: url('../../../Images//checkBoxCheck.png');
    font-size: 14px;
    /* border: none;
    background-color: transparent;
    position: absolute;
    top: 0; */
}

.sameAsAddressCheckBox .form-check-label {
    margin-top: 0;
    margin-left: 8px;
    color: #1D2939;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.dateOfCNICIssuanceInput .row {
    position: relative;
}

.dateOfCNICIssuanceInput input[type="date"]::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
    position: relative;
    font-size: 25px;
    right: -2px;
}

.dateOfCNICIssuanceInput input[type="date"]:before {
    color: transparent;
    background-image: url('../../../Images/calenderIcon.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 21.5px;
    width: 21.5px;
    content: '';
    position: absolute;
    top: 15px;
    right: 20px;
    color: #999;
    z-index: 0;
}

.authDateOfCNICIssuanceInput input[type="date"]::-webkit-calendar-picker-indicator {
    right: 0
}

.authDateOfCNICIssuanceInput input[type="date"]:before {
    background-image: url('../../../Images/calenderAuthIcon.png');
    height: 24px;
    width: 24px;
}

#custom-toast-container {
    max-width: 500px;
    /* adjust the max-width to allow more space for the text */
}

.Toastify__toast-body>div:last-child {
    /* word-break: break-all; */
}

.recharts-label-list {
    width: 100%;
}

.barRect {
    width: 100px;
}

.strongLabel {
    color: #202020;
    font-size: 14px
}

.projection_xAxis {
    font-size: 12px !important;
}


#performance .recharts-curve.recharts-tooltip-cursor {
    stroke: #D5D5D5;
    stroke-width: 1.548px;
}

#performance .recharts-default-tooltip {
    padding: 10px 16px !important;
    border-radius: 10px;
    border: 1px solid #D9D9D9 !important;
    background: #FFF;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15);
}

#performance .recharts-default-tooltip,
#performance .recharts-tooltip-item-list {
    display: flex;
    flex-direction: column;
    gap: 6px
}

#performance .recharts-layer :is(text, tspan) {
    fill: #6B6F80;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: -0.387px;
}

#performance .recharts-tooltip-label {
    color: #313131;
}

#performance .recharts-tooltip-item,
.recharts-tooltip-label {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0 !important;
    margin: 0 !important;
}

@media screen and (min-width: 250px) and (max-width: 450px) {

    .ibftForm button {
        width: 45% !important;
    }

    .payNowTabsContainer button {
        font-size: 12px !important;
    }

    .payNowHeadWrapper img[alt="Nift logo"] {
        width: 100px !important;
    }

    .payNowHeadWrapper img {
        width: 14px !important;
    }

    .payNowHeadWrapper button {
        min-width: auto !important;
    }

    .payNowHeadWrapper p {
        font-size: 20px !important;
    }

    .downloadBtnWrapper {
        flex-direction: column;
    }

    .downloadBtnWrapper a {
        margin-top: 10px;
    }

    .sameAsAddressCheckBox :is(input[type='checkbox'], input[type='checkbox']:checked),
    .sameAsAddressCheckBox input[type='checkbox']:after,
    .sameAsAddressCheckBox input[type='checkbox']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 4px;
        font-size: 10px;
    }

    .sameAsAddressCheckBox label {
        font-size: 13px;
        line-height: 18px;
    }

    .thumb {
        /* height: 115px !important; */
    }

    .img_cont {
        height: 200px;
    }

    .BackButton,
    .NextButton {
        width: 45% !important;
        margin: 5px !important;
    }

    .cfAssetsTabs {
        min-height: auto !important;
        height: 45px !important;
    }

    .cfAssetsTabs .MuiTabs-flexContainer {
        display: flex;
        justify-content: space-between;
    }

    .cfAssetsTabs button {
        font-size: 12px;
        padding: 5px 10px;
        display: flex;
        align-items: center;
    }

    .creditQualityPie .recharts-legend-wrapper {
        bottom: 40px !important;
        width: 100% !important;
        display: flex;
        justify-content: center;
    }

    .creditQualityPie ul li:nth-child(2) {
        padding-right: 0 !important;
    }
}

@media screen and (min-width: 250px) and (max-width: 320px) {
    .ibftForm button {
        width: 100% !important;
    }

    .payNowTabsContainer button {
        font-size: 10px !important;
    }

    .mahaanaLogo {
        width: 150px;
    }

    .cfAssetsTabs button {
        font-size: 10px;
        padding: 5px;
    }

    .cashfund-calculator .recharts-text.recharts-label tspan,
    .cashfund-calculator .recharts-label-list text {
        font-size: 10px;
    }

    .fundExpTabs button {
        width: 50%;
        font-size: 12px !important;
        padding: 8px 0 !important;
    }

    .fundExpTabs .flexContainer button {
        width: 'auto'
    }

}

@media screen and (min-width: 301px) and (max-width: 320px) {
    .barRect {
        width: 80px;
    }
}

@media screen and (max-width: 300px) {
    .dashboard_bottom_cards {
        min-width: 100% !important;
        min-height: 40px !important;
        padding: 4px 14px !important;
    }

    .ibftForm button {
        width: 100% !important;
    }

    .payNowTabsContainer button {
        font-size: 10px !important;
    }

    .payNowHeadWrapper img[alt="Nift logo"] {
        width: 85px !important;
    }

    .payNowHeadWrapper img {
        width: 10px !important;
    }

    .payNowHeadWrapper button {
        min-width: auto !important;
        margin-right: 4px
    }

    .payNowHeadWrapper p {
        font-size: 16px !important;
    }

    .buttonRowWrapper {
        flex-direction: column;
    }

    .BackButton,
    .NextButton {
        width: 100% !important;
        margin: 5px 0 !important;
    }

    .singleLine {
        flex-wrap: wrap
    }

    .barRect {
        width: 65px;
    }

    .fundExpTabs button {
        font-size: 10px !important;
    }
}

@-moz-document url-prefix() {

    .thumb-container,
    .thumb {
        width: 100%;
    }

    .thumb {
        display: flex;
        justify-content: center;
    }

    .thumb-inner {
        width: 45%;
    }

    .thumb-inner a {
        width: fit-content;
        max-width: 100%;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 800px) {
    .headerDropdownItem .dropdown-toggle {
        font-family: 'Poppins' !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        color: #202020 !important;
        width: 100% !important;
    }
}