.ConfirmationContainer {
  height: 100vh;
}
.ConfirmationRow {
  height: 100%;
  justify-content: center;
  align-items: center;
}
.ConfirmationCard {
  box-shadow: 0px 0px 15px rgb(179 174 174 / 41%) !important;
}
.ConfirmationContentDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.ConfirmationContentIcon {
  font-size: 115px;
  color: #4012a0;
}
.ConfirmationContentButton {
  margin-top: 25px;
}
