.tabs {
  align-items: center;
  /* display: flex; */
  /* justify-content: space-between; */
  border-bottom: 5px solid #000000;
}

.fa.thematic-icons {
  color: #000000;
  font-size: 50px;
  text-align: center;
}

.thematic,
.thematic * {
  /* color: #000000; */
}

.top {
  padding: 75px 50px;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.top h1 {
  font-size: 2.5rem;
}

.tab {
  width: 20%;
  padding: 10px 0;
}

.tab p {
  margin: 0;
}

.thematic-box {
  background: #e2e2e2;
  padding: 10px;
}

.thematic-check input[type='checkbox'] {
  background: #ffffff;
}

.head {
  font-family: 'Lato';
  font-weight: 500;
  font-size: 22.3px;
}

.investButton {
  background-color: #49BDD8 !important;
  color: white !important;
  border: 0px solid transparent;
  padding: 15px;
  padding-left: 50px;
  padding-right: 50px;
  /* box-shadow:  rgba(63, 140, 255, 0.2637) !important; */
  border-radius: 15px;
}